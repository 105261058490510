@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 11, 11, 11;
  --background-end-rgb: 11, 11, 11;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 11, 11, 11;
    --background-end-rgb: 11, 11, 11;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
                  to bottom,
                  transparent,
                  rgb(var(--background-end-rgb))
  ) rgb(var(--background-start-rgb));
}


::-webkit-scrollbar {
  width: 5px;
}

//&::-webkit-scrollbar-button {
//  background: var(--background-start-rgb)
//}
//&::-webkit-scrollbar-track-piece {
//  background: var(--background-start-rgb)
//}

::-webkit-scrollbar-track {
  background: var(--background-start-rgb);
}

::-webkit-scrollbar-thumb {
  background-color: #FFFFFFAD;
  border-radius: 3px;
}


@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}


/*here*/
.theme-dark {
  --background-site-rgb-value: 0, 0, 0;
  --brand-primary: rgb(138, 180, 248);
  --brand-secondary: rgb(193, 168, 226);
  --background-site: rgb(11, 11, 11);
}
